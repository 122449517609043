// @flow
export const ArtistName = "taylor swift";

export const ModalText =
  'Out Swifting the Swifters.<br />But just look what you created<br /><br />-Mr. Perfectly Fine<br />#CannonBall';

// Ordered list of albums for selector - keep in sync with album map
export const ALBUMS = [
  "Taylor Swift",
  "Beautiful Eyes",
  "Fearless",
  "Speak Now",
  "Red",
  "1989",
  "reputation",
  "Lover",
  "folklore",
  "evermore",
  "Midnights",
  "The Tortured Poets Department",
  "Collaborations",
  "Movie Soundtracks",
  "Holiday Music"
];
